































































import { defineComponent } from '@vue/composition-api'
import NbTextbox from '@/components/NbTextbox.vue'

export default defineComponent({
	name: 'ContactUsPage',

	components: {
		NbTextbox
	},

	setup() {
		// TODO	
	}
})
