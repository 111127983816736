import { render, staticRenderFns } from "./ContactUsPage.vue?vue&type=template&id=42c71728&scoped=true&"
import script from "./ContactUsPage.vue?vue&type=script&lang=ts&"
export * from "./ContactUsPage.vue?vue&type=script&lang=ts&"
import style0 from "./ContactUsPage.vue?vue&type=style&index=0&id=42c71728&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42c71728",
  null
  
)

export default component.exports